import Vue from 'vue'
import router from '../../router/index'
import i18n from '../../plugins/i18n'

const state = {
  id: null,
  name: null,
  surname: null,
  email: null,
  foodytekClientId: null,
  token: null,
  tokenExpiration: null,
  role: null,
  registrationComplete: null
}

const getters = {
  isAuthenticated: (state) => {
    return state.token !== null
  },
  registrationComplete: (state) => {
    return state.registrationComplete
  },
  token: (state) => {
    return state.token
  },
  foodytekClientId: (state) => {
    return state.foodytekClientId
  },
  email: (state) => {
    return state.email
  },
  name: (state) => {
    return state.name
  },
  surname: (state) => {
    return state.surname
  },
  role: (state) => {
    return state.role
  },
  isClient: (state) => {
    return state.role === 'foodytek-client-admin'
  },
  tokenExpiration: (state) => {
    return state.tokenExpiration
  }
}

function setHeaders (token) {
  const headers = token ? {
    Authorization: 'Bearer ' + token
  } : {}
  Vue.prototype.$http.auth.defaults.headers = headers
  Vue.prototype.$http.device.defaults.headers = headers
}

const mutations = {
  loginUser (state, {
    userData,
    redirectTo,
    preventRedirect
  }) {
    console.log('loginUser')
    Object.assign(state, userData)
    setHeaders(state.token)

    if (!preventRedirect) {
      router.push({
        path: '/redirect',
        query: preventRedirect ? {
          preventRedirect
        } : redirectTo ? {
          to: redirectTo
        } : {}
      })
    }
  },

  logoutUser (state, props) {
    Object.keys(state).forEach(k => {
      state[k] = null
    })
    setHeaders(state.token)

    if (!props?.doNotRedirect) {
      router.push('/login')
    }
  }
}

function generateError (messageCode, append) {
  console.log(append)
  const message = i18n.t(messageCode) + (append ?? '')
  return message
}

function handleLogin (state, commit, { apiResponse, redirectTo, preventRedirect }) {
  const isConsumerAcceptingInvitation = apiResponse.role === 'consumer' && !preventRedirect
  if (isConsumerAcceptingInvitation || apiResponse.role === 'foodytek-client-installer') {
    Vue.prototype.$showError(generateError('commons.consumerNotAllowed'))
  } else {
    commit('loginUser', {
      userData: apiResponse,
      redirectTo,
      preventRedirect
    })
  }
}

const actions = {
  async login ({ commit }, { authCredentials, redirectTo, preventRedirect }) {
    const response = await Vue.prototype.$http.auth.post('login', authCredentials)
    handleLogin(state, commit, {
      apiResponse: response.data,
      redirectTo,
      preventRedirect
    })
  },
  async loginViaOtp ({ commit }, { authCredentials, redirectTo, preventRedirect }) {
    const response = await Vue.prototype.$http.auth.post('login/email/otp', authCredentials)
    handleLogin(state, commit, {
      apiResponse: response.data,
      redirectTo,
      preventRedirect
    })
  },
  renewToken ({ commit }, { token, redirectTo }) {
    console.log('renewToken')
    Vue.prototype.$http.auth.get('/login/renew', {
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
        .then((response) => {
          handleLogin(state, commit, {
            apiResponse: response.data,
            redirectTo
          })
        }).catch(() => {
      commit('logoutUser')
    })
  }
}

export default {
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
