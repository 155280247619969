<template src="./Login.html"/>

<script>
export default {
  name: 'Login',
  data () {
    const { isAuthenticated } = this.$store.getters
    const { invitationToken } = this.$route.query
    let step = 'home'
    if (isAuthenticated && invitationToken) {
      step = 'finalise-registration'
    }

    return {
      step,

      emailForm: null,
      email: this.$route.query.email || '',
      passwordForm: null,
      password: '',
      otp: '',

      // Registration fields
      givenName: '',
      familyName: '',
      phoneNumber: '',
      confirmPassword: '',
      wantsPassword: false,

      finaliseRegistrationForm: null,
      registrationForm: null,

      isRegistration: false, // used during registration in OTP step
      hasPassword: false, // used to track if user has password

      loading: false,

      showPassword: false,

      stepperContentStyle: {
        // height: '170px'
        // minHeight: '150px'
      }
    }
  },
  methods: {
    changeEmail() {
      if (this.$store.getters.isAuthenticated) {
        this.$store.commit('logoutUser', { doNotRedirect: true })
      }
      this.step = 'home'
    },
    continueWithEmailBtn () {
      if(!this.emailForm) return
      this.continueWithEmail()
    },
    async continueWithEmail () {
      if(this.loading) return

      this.loading = true
      try {
        const {data} = await this.$http.auth.post('login/email', {
          email: this.email
        })
        this.isRegistration = !data.isRegistered
        this.hasPassword = data.hasPassword

        if (!data.isRegistered) {
          const { invitationToken } = this.$route.query
          if (invitationToken) {
            // If the user is not registered and there is an invitation token, go to registration form
            this.step = 'register'
            return
          } else {
            // Normal user login, therefore it should exist in order to use the dashboard
            this.$dialog.error({
              title: 'No account found',
              text: 'No account found with this email address. Please use the same email address you used to register.'
            })
            return
          }
        }

        if (data.hasPassword) {
          this.step = 'password'
        } else {
          this.step = 'insert-otp'
        }
      } finally {
        this.loading = false
      }
    },
    async goToOTPLogin () {
      try {
        await this.$http.auth.post('login/email/request-otp', {
          email: this.email
        })
      } catch (e) {
        return
      }

      this.step = 'insert-otp'
    },
    async continueRegistration() {
      if (!this.registrationForm) return
      
      this.step = 'insert-otp'
    },
    async loginViaOtp () {
      if(this.loading) return

      this.loading = true

      try {
        if (this.isRegistration) {
          // Registration flow
          const res = await this.$http.auth.post('sign-up/email', {
            email: this.email,
            givenName: this.givenName,
            familyName: this.familyName,
            otp: this.otp
          })
          
          this.$store.commit('loginUser', {
            userData: res.data,
            preventRedirect: true
          })
          // After successful registration, go to ask-add-password step
          this.step = 'ask-add-password'
        } else {
          // Login flow
          await this.$store.dispatch('loginViaOtp', {
            authCredentials: {
              email: this.email,
              otp: this.otp
            },
            redirectTo: this.$route.query.to,
            preventRedirect: !!this.$route.query.invitationToken // prevents auto-redirect
          })
          if (this.hasPassword) {
            this.step = 'finalise-registration'
          } else {
            this.step = 'ask-add-password'
          }
        }
      } finally {
        this.loading = false
      }
    },
    loginViaPasswordBtn () {
      if (!this.passwordForm) return
      this.loginViaPassword()
    },
    async loginViaPassword () {
      if(this.loading) return

      this.loading = true

      try {
        await this.$store.dispatch('login', {
          authCredentials: {
            email: this.email,
            password: this.password
          },
          redirectTo: this.$route.query.to,
          preventRedirect: !!this.$route.query.invitationToken // prevents auto-redirect
        })
      } finally {
        this.loading = false
      }
    },
    async handleAddPasswordChoice() {
      if (this.wantsPassword) {
        this.step = 'add-password'
      } else {
        this.step = 'finalise-registration'
      }
    },
    async addPassword() {
      if (this.loading) return
      if (this.password !== this.confirmPassword) {
        this.$dialog.error({
          title: this.$t('Error'),
          text: this.$t('Passwords do not match')
        })
        return
      }

      this.loading = true
      try {
        await this.$http.auth.patch('users/password', {
          newPassword: this.password
        })
        this.step = 'finalise-registration'
      } finally {
        this.loading = false
      }
    },
    async acceptInvite () {
      if (!this.finaliseRegistrationForm) return
      this.loading = true
      try {
        const response = await this.$http.auth.patch(
            `invitations/token/${this.$route.query.invitationToken}/accept`,
            {
              mobilePhoneNumber: this.phoneNumber
            }
        )
        this.loading = false
        console.log('ciao')
        this.$store.commit('loginUser', response.data)
        this.loginUser('ciao')
      } catch (e) {
        this.loading = false
      }
    },
  }
}
</script>
