import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import i18n from './plugins/i18n'
import './plugins/vuetify-dialog'
import axiosInstances from './plugins/axios'
import FlagIcon from 'vue-flag-icon'
import { store } from './store/store'
import { LoadingModal } from '@simple-it/shared-vuetify'
import '@/plugins/vee-validate'
import rules from "@/mixins/rules";

Vue.use(FlagIcon)
Vue.component('LoadingModal', LoadingModal)
Vue.mixin(rules)

Vue.config.productionTip = false

Vue.prototype.$http = axiosInstances

if (store.getters.language) {
  i18n.locale = store.getters.language
} else {
  store.commit('changeLang', navigator?.language.split('-')[0] ?? 'en')
}

const vueApp = new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App)
})
vueApp.$mount('#app')
