import Vue from 'vue'
import VueRouter from 'vue-router'
import {store} from '@/store/store'

import Login from '../views/Login/Login'
import Registration from '../views/Registration/Registration'
import FoodyTekClientRegistration from '@/views/FoodyTekClientRegistration/FoodyTekClientRegistration'
import Redirect from '@/views/Redirect/Redirect'
import ClientCustomerRegistration from "@/views/ClientCustomerRegistration/ClientCustomerRegistration.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/logout',
    beforeEnter (to, from, next) {
      store.commit('logoutUser')
      next('/login')
    }
  },
  {
    path: '/login',
    component: Login,
    // fixme: can be here if authenticated but should have invitation token and must be consumer

    // beforeEnter (to, from, next) {
    //   store.getters.isAuthenticated
    //       ? store.getters.registrationComplete
    //           ? next(pathWithParam('/redirect', to.query))
    //           : next('/register/ft-client')
    //       : next()
    // }
  },
  {
    path: '/register/ft-client',
    component: FoodyTekClientRegistration,
    beforeEnter (to, from, next) {
      if (store.getters.isAuthenticated) {
        if (store.getters.registrationComplete) next('/')
        else if (store.getters.isClient) {
          if (!store.getters.foodytekClientId) next()
          else next('/redirect')
        }
        else next('/register/client-customer')
      }
      else next('/login')
    }
  },
  {
    path: '/register/client-customer',
    component: ClientCustomerRegistration,
    beforeEnter (to, from, next) {
      if (store.getters.isAuthenticated) {
        if (store.getters.registrationComplete) next('/')
        else if (store.getters.isClient) next('/register/ft-client')
        else next()
      }
      else next('/login')
    }
  },
  {
    path: '/register/:registrationToken',
    component: Registration
  },
  {
    path: '/redirect',
    component: Redirect,
    beforeEnter (to, from, next) {
      if (store.getters.isAuthenticated) {
        if (store.getters.registrationComplete) {
          next()
        } else if (store.getters.isClient) {
          if (!store.getters.foodytekClientId) next('/register/ft-client')
          else next()
        } else {
          next('/register/client-customer')
        }
      } else {
        next(pathWithParam('/login', to.query))
      }
    }
  },
  {
    path: '*',
    redirect: '/redirect',
    beforeEnter (to, from, next) {
      // if (store.getters.isAuthenticated) {
      //   if (store.getters.registrationComplete) {
      //     next(pathWithParam('/redirect', to.query))
      //   } else {
      //     next('/register/ft-client')
      //   }
      // } else {
      //   next()
      // }

      // localhost:8080/login?continueRegistrationToken=bab6a892-83b8-4fc9-98cf-351f80da954a

      if (store.getters.isAuthenticated && !store.getters.registrationComplete) {
        next('/register/ft-client')
      } else {
        next(pathWithParam('/redirect', to.query))
      }
    }
  }
]

function pathWithParam (path, params) {
  const esc = encodeURIComponent
  path += '?' + Object.keys(params)
      .map(k => esc(k) + '=' + esc(params[ k ]))
      .join('&')
  return path
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
